<template>
    <base-dialog width="640" :is-open.sync="isOpenLocal">
        <v-card-title style="background-color: #f1eeee">
            <span style="color: #78756a" class="font-weight-bold"
                >View Event
            </span>
            <v-btn
                class="border-radius-25 px-5 ml-5"
                depressed
                rounded
                dark
                color="#389877"
                @click="$emit('showUpdateForm')"
                >Update</v-btn
            >
        </v-card-title>
        <v-card-text>
            <v-row class="px-5 my-2">
                <v-col cols="12">
                    <v-img
                        class="border-radius-10"
                        :lazy-src="
                            data.image
                                ? data.image
                                : 'https://picsum.photos/id/11/500/300'
                        "
                        :src="
                            data.image
                                ? data.image
                                : 'https://picsum.photos/id/11/500/300'
                        "
                    ></v-img>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            EVENT NAME
                        </v-col>

                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.event_name }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            DESCRIPTION
                        </v-col>
                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.description }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            DATE AND TIME
                        </v-col>
                        <v-col cols="12" md="8" class="font-size-16">
                            {{ schedule }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            LOCATION
                        </v-col>
                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.location }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            EVENT TYPE
                        </v-col>
                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.event_type }}
                        </v-col>
                    </v-row>
                </v-col>

                <!--                <v-col cols="12">-->
                <!--                    <v-row>-->
                <!--                        <v-col-->
                <!--                            cols="12"-->
                <!--                            md="4"-->
                <!--                            class="font-weight-bold d-flex align-center"-->
                <!--                        >-->
                <!--                            INVITE LINK-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="8" class="font-size-16">-->
                <!--                            <span-->
                <!--                                ><span class="text-decoration-underline">{{-->
                <!--                                    data.invite_link-->
                <!--                                }}</span>-->
                <!--                                <v-tooltip top>-->
                <!--                                    <template v-slot:activator="{ on, attrs }">-->
                <!--                                        <v-btn-->
                <!--                                            v-bind="attrs"-->
                <!--                                            v-on="on"-->
                <!--                                            @click="copyToClipboard"-->
                <!--                                            icon-->
                <!--                                            ><v-icon small color="#369776"-->
                <!--                                                >mdi-content-copy</v-icon-->
                <!--                                            ></v-btn-->
                <!--                                        >-->
                <!--                                    </template>-->
                <!--                                    <span>Copy Invite Link</span>-->
                <!--                                </v-tooltip></span-->
                <!--                            >-->
                <!--                        </v-col>-->
                <!--                    </v-row>-->
                <!--                </v-col>-->
                <!--              -->
            </v-row>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="error"
                            rounded
                            @click="openAlertDialog"
                            >Delete</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>

        <base-alert-dialog
            :is-open.sync="alertDialog.open"
            :title="alertDialog.title"
            :description="alertDialog.description"
            :theme="alertDialog.theme"
            :loading="isActionStart"
            @onCancel="alertDialog.open = false"
            @onProceed="onDelete"
        ></base-alert-dialog>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import dateUtility from "@/mixins/date";
import helperUtility from "@/mixins/helper";
import { DELETE_EVENT } from "@/store/modules/event";
import BaseAlertDialog from "@/components/Base/BaseAlertDialog";
export default {
    name: "engage-event-information-dialog",

    components: { BaseAlertDialog, BaseDialog },

    props: {
        isOpen: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
            isActionStart: false,
            alertDialog: {
                open: false,
                theme: "warning",
                title: null,
                description: null,
            },
        };
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },

        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },
    },

    computed: {
        schedule() {
            const { is_allday, event_start, event_end } = this.data;
            let start = dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(event_start)
            );
            if (!is_allday) {
                start = dateUtility.formatTimestampToSchedule(
                    dateUtility.toLocalDate(event_start)
                );
                const end = dateUtility.formatTimestampToSchedule(
                    dateUtility.toLocalDate(event_end)
                );

                return `${start} to ${end}`;
            }

            return `${start} (All Day)`;
        },
    },

    methods: {
        copyToClipboard() {
            helperUtility.copyText(this.data.invite_link);
            this.$notify({
                group: "main-notifications",
                type: "success",
                title: "Copied to Clipboard",
                text: "Invite Link was copied to clipboard.",
            });
        },

        closeForm() {
            this.$emit("close");
        },

        async onDelete() {
            this.isActionStart = true;
            const { success } = await this.$store.dispatch(
                DELETE_EVENT,
                this.data.id
            );
            if (success) {
                this.alertDialog.open = false;
                this.isActionStart = false;
                this.closeForm();
                this.$emit("onDeleteSuccess");
                this.$notify({
                    group: "main-notifications",
                    type: "error",
                    duration: 5000,
                    title: "Engage",
                    text: "Event was deleted.",
                });
                return;
            }
            this.isActionStart = false;
        },

        openAlertDialog() {
            this.alertDialog = Object.assign(
                {},
                {
                    ...this.alertDialog,
                    title: "Event",
                    description: `Are you sure you want to delete ${this.data.event_name}? Click proceed if you are sure.`,
                    open: true,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;
    background-color: #2c423d;
    color: white;
}
</style>
